<template>
  <div>
    <CForm @submit="submitFilterValues">
      <CCard>
        <CButton
          @click="cardCollapse = !cardCollapse"
          tag="button"
          color="link"
          block
          class="flex flex--space-between text-left shadow-none card-header"
        >
          <h5 class="m-0">Filtros</h5>
          <CIcon :name="`cil-chevron-${cardCollapse ? 'top' : 'bottom'}`" />
        </CButton>
        <CCollapse :show="cardCollapse">
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <label class="filter-label">{{ messages.filters.area }}</label>
                <FiltersAreaCombo :activeElements="activeItems" />
                <span
                  v-if="areaError"
                  class="error-message error-message--bottom"
                  >{{ messages.validation.filterAreaCombo }}</span
                >
              </CCol>
            </CRow>
            <CRow>
              <CCol xl="3">
                <label class="filter-label">FY</label>
                <FilterFY />
              </CCol>
              <CCol xl="6">
                <label class="filter-label">{{
                  messages.filters.period
                }}</label>
                <FilterMonthsRange />
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" color="primary">Mostrar</CButton>
          </CCardFooter>
        </CCollapse>
      </CCard>
    </CForm>
    <CRow v-if="showLoader" class="justify-content-center">
      <Loader
        width="100px"
        height="100px"
        fill-color="#c2c2c2"
        cssClass="fadeIn anim-speed--1s"
      />
    </CRow>
    <CRow v-if="showTable" class="fadeIn anim-speed--2s">
      <CCol sm="12">
        <TableClosureForecast
          :fields="processedData.fields"
          :items="processedData.items"
          :thead="theadTopCells"
        > 
          <span slot="area">{{ selectedArea }}{{ selectedArea && ' - ' }}</span>
          <span slot="period">{{ selectedPeriod }}</span>
          <span slot="fiscalYear">{{ selectedFY }}</span>
        </TableClosureForecast>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Api from "@/services/Api";
import currentFY from "@/services/CurrentFY";
import formatNum from "@/utils/formatNum";
import defaultAreaParam from "@/helpers/defaultAreaParam";
import formatFiscalYear from "@/helpers/formatFiscalYear";
import getAreaName from "@/helpers/getAreaName";

const FiltersAreaCombo = () =>
  import(
    /* webpackChunkName: "filtersAreaCombo" */ "@/components/organisms/FiltersAreaCombo"
  );
const FilterFY = () =>
  import(
    /* webpackChunkName: "filter-fiscal-year" */ "@/components/molecules/FilterFY"
  );
const FilterMonthsRange = () =>
  import(
    /* webpackChunkName: "filterMonthsRange" */ "@/components/molecules/FilterMonthsRange"
  );
const TableClosureForecast = () =>
  import(
    /* webpackChunkName: "table-closure-forecast" */ "@/components/organisms/TableClosureForecast"
  );
const Loader = () =>
  import(/* webpackChunkName: "loader" */ "@/components/atoms/Loader");

export default {
  name: "ClosureForecast",
  components: {
    FiltersAreaCombo,
    FilterFY,
    FilterMonthsRange,
    TableClosureForecast,
    Loader,
  },
  data() {
    return {
      filtersList: null,
      cardCollapse: true,
      activeItems: ["countries", "regionals", "aspms", "concessions"],
      areaError: false,
      showLoader: false,
      showTable: false,
      theadTopCells: null,
      processedData: null,
      selectedArea: null,
      selectedPeriod: null,
      selectedFY: null,
    };
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
    implicitParams() {
      const n = this.user.profileId;
      if (n === 10) {
        return {
          aspm: this.user.aspm,
        };
      }
      return null;
    },
  },
  methods: {
    defaultQuery() {
      currentFY().then((currentFY) => {
        const defaultArea = defaultAreaParam(this.user);
        const defaults = {
          fiscalYear: currentFY,
          sinceMonth: new Date().getMonth() + 1,
          upToMonth: new Date().getMonth() + 1,
        };
        defaults[defaultArea.key] = defaultArea.value;
        console.log("defaults", defaults);
        this.makeApiQuery(defaults);
      });
    },
    submitFilterValues(event) {
      event.preventDefault();
      /**
       * Variable to check required values
       * Define parameters
       */
      let params,
        validated = false;

      params = { ...this.$store.getters.getFiltersAreaComboParams };
      if (!this.$store.getters.getFiltersAreaComboParams) {
        // Show area filter validation error
        this.areaError = true;
      } else {
        // Hide area filter validation error
        this.areaError = false;
        validated = true;
      }

      if (validated) {
        params.fiscalYear = this.$store.getters.getFilterFYValue;
        params.sinceMonth = this.$store.getters.getFilterMonthSinceValue;
        params.upToMonth = this.$store.getters.getFilterMonthUpToValue;
        console.log("QUERY PARAMS", params);
        // Make API query with filter values
        this.makeApiQuery(params);
      }
    },
    makeApiQuery(params) {
      // Show the loader and hide the table until data is received
      this.showLoader = true;
      this.showTable = false;
      // API call sending parameters
      Api.sendPost("commercial/closingForecastReport", params).then(
        (response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              // // Hide the loader and sow the table
              this.showLoader = false;
              this.showTable = true;
              // // Set the processed data variable to send to the DataTable component
              const filteredData = this.getFilteredData(response.data.data);
              this.processedData = {
                ...this.processRawData(filteredData),
              };
              this.updateTableTitle(params);
              this.theadTopCells = this.getTheadTopCells(
                Object.keys(filteredData[0]).length - 1,
                filteredData[0].productContentList
              );
              //console.table(response.data.data);
            }
          }
        }
      );
    },
    getFilteredData(data) {
      const group1 = ["BV", "ACC"];
      const group2 = ["T05", "T03", "BPC"];
      const group3 = ["REMANVA"];
      const filteredData = data.map((row) => {
        const processedRow = {
          countryCode: row.countryCode,
          regionalCode: row.regionalCode,
          aspm: row.aspm,
          concessionCode: row.concessionCode,
          businessName: row.businessName,
          updatedAt: row.updatedAt,
          productContentList: [],
        };
        row.productContentList.map((product) => {
          if (group1.indexOf(product.productCode) > -1) {
            processedRow.productContentList.push({
              productCode: product.productCode,
              targetPurchases: product.targetPurchases,
              purchases: product.purchases,
              prevision: product.prevision,
              previsionCompletion: product.previsionCompletion,
              previsionByAspm: product.previsionByAspm,
              previsionByAspmCompletion: product.previsionByAspmCompletion,
            });
          }
          if (group2.indexOf(product.productCode) > -1) {
            processedRow.productContentList.push({
              productCode: product.productCode,
              purchases: product.purchases,
              prevision: product.prevision,
              previsionUIO: product.previsionUIO,
              previsionByAspm: product.previsionByAspm,
              previsionByAspmUIO: product.previsionByAspmUIO,
            });
          }
          if (group3.indexOf(product.productCode) > -1) {
            processedRow.productContentList.push({
              productCode: product.productCode,
              purchases: product.purchases,
              prevision: product.prevision,
              previsionUIO: product.previsionUIO,
            });
          }
        });
        return {
          ...processedRow,
        };
      });

      return filteredData;
    },
    getTheadTopCells(firstSpanLength, productsList) {
      const theadTopCells = [
        {
          name: "",
          span: firstSpanLength,
        },
      ];
      productsList.map((product) => {
        const cell = {
          name: this.messages.products.byCodeList[product.productCode],
          span: Object.keys(product).length - 1,
        };
        theadTopCells.push(cell);
      });
      return theadTopCells;
    },
    makeFields(obj, code) {
      const fields = [];
      for (let prop in obj) {
        if (prop !== "productContentList" && prop !== "productCode") {
          const key = code !== undefined ? `${code}${prop}` : prop;
          const field = {
            key: key,
            label: this.messages.closureForecastTable[prop].toUpperCase(),
          };
          fields.push(field);
        }
      }
      return fields;
    },
    getTableFields(data) {
      const generalFields = this.makeFields(data[1]);
      const productsFields = data[1].productContentList.map((product) => {
        return this.makeFields(product, product.productCode);
      });
      const prodFieldsReduced = productsFields.reduce((a, b) => a.concat(b));
      return generalFields.concat(prodFieldsReduced);
    },
    getTableItems(data) {
      const tableItems = [];
      data.map((row) => {
        const newRow = {};
        for (let prop in row) {
          if (prop !== "productContentList") {
            let value =
              row[prop] !== null && row[prop] !== undefined ? row[prop] : "";
            if (
              (prop === "countryCode" || prop === "regionalCode") &&
              row[prop] !== "TOTAL" &&
              row[prop] !== null
            ) {
              value = this.messages.areaByCode[row[prop]].toUpperCase();
            }
            newRow[prop] = value;
          } else {
            row.productContentList.map((product) => {
              for (let prop in product) {
                if (prop !== "productCode") {
                  const key = `${product.productCode}${prop}`;
                  const suffix =
                    prop !== `previsionCompletion` &&
                    prop !== `previsionByAspmCompletion`
                      ? "€"
                      : "%";
                  newRow[key] = !isNaN(product[prop])
                    ? `${formatNum.get(product[prop], 2)}${suffix}`
                    : "";
                }
              }
            });
          }
        }
        if (row.countryCode === "TOTAL") newRow._classes = "dark-gray";
        tableItems.push(newRow);
      });
      return tableItems;
    },
    processRawData(data) {
      return {
        fields: [...new Set(this.getTableFields(data))],
        items: [...new Set(this.getTableItems(data))],
      };
    },
    updateTableTitle(params) {
      this.selectedArea = getAreaName(params, this.messages);
      this.selectedFY = formatFiscalYear(params.fiscalYear);
      this.selectedPeriod = params.sinceMonth === params.upToMonth ?
      this.messages.filters.months[params.sinceMonth]
      : `de ${
        this.messages.filters.months[params.sinceMonth]
      } a ${
        this.messages.filters.months[params.upToMonth]
      }`;
    }
  },
  created() {
    this.defaultQuery();
  },
};
</script>
